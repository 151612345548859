/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/
import React, { Component, useState } from 'react';
import { Header, Icon, Notification } from '@scuf/common';
import CustomSignIn from '../CustomAuth/CustomSignIn';
import User from '../user/user';
import OrgAdmin from '../OrgAdmin/OrgAdmin';
import HQSAdmin from '../HQSAdmin/HQSAdmin';
import Product from '../Product/Product';
import ProtectedRoute from '../CustomAuth/ProtectedRoute';
import SignUp from '../SignUp/SignUp';
import SignUpComplete from '../SignUp/SignUpComplete';
import CustomRequireTermsAndConditions from '../CustomAuth/CustomRequireTermsAndConditions';
import CustomForgotPassword from '../CustomAuth/CustomForgotPassword';
import CustomConfirmForgotPassword from '../CustomAuth/CustomConfirmForgotPassword';
import CustomRequireMFA from '../CustomAuth/CustomRequireMFA';
import { modes, hubChannels } from '../utils/helpers';
import { BrowserRouter, Switch, Route, NavLink, Redirect, Routes } from 'react-router-dom';
import { version } from '../../../package.json';
import SignOutAzure from '../Forms/SignOutAzure';
import CustomSoftwareSignUp from '../CustomAuth/CustomSoftwareSignUp';
import SignUpSoftware from '../SignUp/SignUpSoftware';
import { MsalContext } from '@azure/msal-react';
import { AmplifyConfig } from '../config';
import Amplify from 'aws-amplify';



import './App.css';

const logo = require('./QuantinuumLogo.svg');
const views = {
    SIGN_UP_VIEW : 'signup',
    USER_VIEW :'user',
    ORG_ADMIN_VIEW : 'orgAdmin',
    OPERATOR_VIEW : 'operator',
    HQS_ADMIN_VIEW : 'hqsAdmin',
    PRODUCT_VIEW : 'product'
}

Amplify.configure(AmplifyConfig);

class App extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props)

    }


    createProfileItem(viewName, displayName) {
        return (
            <NavLink key={viewName} className='hqs-menu-item' activeClassName='hqs-menu-active-item' to={'/' + viewName} onClick={() => this.hideDropDownMenu()}>{displayName}</NavLink>
        )
    }

    hideDropDownMenu() {
        let menu = document.querySelector('.menu.transition.right')
        if (menu != null) {
            menu.style.setProperty('display', 'flex', 'important')
        }
    }

    parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    onSignOutClick(){
        localStorage.clear()
        sessionStorage.clear()
        window.location.replace("/login");
    }

    createUserItems() {
        let userGroups = []
        let storedToken = localStorage.getItem("id-token")
        if (storedToken !== 'undefined' && storedToken !== null) {
            let idToken = this.parseJwt(storedToken)
            if("cognito:groups" in idToken) {
                userGroups = idToken["cognito:groups"]
            }
        }
        
        const userProfileItems = [];
        if (userGroups.includes('hqs-admins')) {
            userProfileItems.push(
                <span key="hqs-app-version" className='hqs-app-version'>{'Version: ' + version}</span>
            );
        }
        //always display user item
        if (!userGroups.includes('user')) {
            userProfileItems.push(
                this.createProfileItem('user', 'User')
            );
        }

        if (userGroups.includes('org-admins')) {
            userProfileItems.push(
                this.createProfileItem('orgAdmin', 'Org Admin'),
            );
        }

        if (userGroups.includes('operators')) {
            userProfileItems.push(
                this.createProfileItem('operator', 'Operator'),
            );
        }

        if (userGroups.includes('hqs-admins')) {
            userProfileItems.push(
                this.createProfileItem('hqsAdmin', 'QTM Admin'),
            );
        }

        if (userGroups.includes('product')) {
            userProfileItems.push(
                this.createProfileItem('product', 'Product'),
            );
        }
                
        //dynamically render the logout button as one that supports logging out of Microsoft or fallback on the native logout with Cognito
        const isMSAuthenticated = this.context.accounts.length > 0;

        if (isMSAuthenticated) {
            const azureLogout = (<SignOutAzure key="sign-out-msal" />);
            userProfileItems.push(azureLogout);
            
        } else {
            const logOutItem = (
                <NavLink key="sign-out-cognito" className='hqs-menu-item' to='/login' onClick={this.onSignOutClick}>Logout</NavLink>
            );
            userProfileItems.push(logOutItem);
        }


        return userProfileItems;
    }

    render(){
        let authenticated = localStorage.getItem("isAuthenticated")
        let header = (<div></div>)
        if (authenticated) {
            header = (
                <>
                <Header key="mymenu" menu={false} logoUrl={logo} collapseAt={1645}>
                        {this.createUserItems()}
                        
                </Header>
                {/* depcrecation banner*/}
                    <div class="hqs-notification-banner"><span></span><Icon root="common" name="badge-info" size="small" style={{paddingBottom: 4}} /> Please note, this platform is being replaced by Quantinuum Nexus as previously communicated, and will be deprecated on 31st March 2025. Please use <a href="https://nexus.quantinuum.com/" target="_blank">https://nexus.quantinuum.com/</a> with your existing credentials to submit jobs and manage users.</div>
                </>
            )
        }

        const queryParams = new URLSearchParams(window.location.search);
        const software = queryParams.get('software')

        return (
            // <Router>
            //     {/* <Routes> */}
            //         <Switch>
            //             <Route path="/login">
            //                 <CustomSignIn />
            //             </Route>
            //             <Route path="/user">
            //                 <User />
            //             </Route>
            //         </Switch>
            //     {/* </Routes> */}
            // </Router>
            
            // <CustomSignIn />
            // <AuthContext>
                <BrowserRouter>
                {/* <Routes> */}
                    <div className="App">
                        {header}
                        {/* <Route path="/" component={CustomSignIn}/> */}
                        <Route exact path="/">
                            <Redirect to="/user" />
                        </Route>
                        <Route exact path="/login" component={CustomSignIn}/>
                        <Route exact path="/signup" component={!software ? SignUp: SignUpSoftware} />
                        <Route exact path="/registration-completed" component={SignUpComplete}/>
                        <Route exact path="/terms" component={CustomRequireTermsAndConditions}/>
                        <Route exact path="/software" component={CustomSoftwareSignUp}/>
                        <Route exact path="/mfa" component={CustomRequireMFA}/>
                        <Route exact path="/recover" component={CustomForgotPassword}/>
                        <Route exact path="/recover-confirm" component={CustomConfirmForgotPassword}/>
                        <ProtectedRoute exact path="/user" component={User}/>
                        <ProtectedRoute exact path="/hqsAdmin" component={() => <HQSAdmin mode={modes.hqsAdmin}/>}/>
                        <ProtectedRoute exact path="/product" component={Product}/>
                        <ProtectedRoute exact path="/operator" component={() => <HQSAdmin mode={modes.operator}/>}/>
                        <ProtectedRoute exact path="/orgAdmin" component={OrgAdmin}/>
                    </div>
                </BrowserRouter> 
                   
            // </AuthContext>
            
        )
    }
    
}

export default App;